import { render, staticRenderFns } from "./MatchDaytime.vue?vue&type=template&id=1d56d377&scoped=true"
import script from "./MatchDaytime.vue?vue&type=script&lang=js"
export * from "./MatchDaytime.vue?vue&type=script&lang=js"
import style0 from "./MatchDaytime.vue?vue&type=style&index=0&id=1d56d377&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d56d377",
  null
  
)

export default component.exports