<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="24">
          <el-scrollbar :native="false">
            <el-row type="flex">
              <el-col :span="24" style="min-width:900px">
                <el-form-item
                  prop="daytime_node.data.keyword_conditions.data"
                  :label="__('Match Day and Time')"
                >
                  <match-daytime
                    v-model="keywordConditions"
                    :gotoOptions="generateGotoOptions"
                    style="width: 100%;"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-scrollbar>
        </el-col>
      </el-row>

      <el-row type="flex" v-if="showTimeZone" id="timezoneDropdown">
        <el-col :span="24">
          <el-form-item
            :label="__('Timezone')"
            prop="selected_timezone"
            class="is-required"
          >
            <timezone-dropdown
              :default_timezone="this.timezoneSelection"
              @timezone-changed="handleTimezoneChange"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="otherwise"
            :label="__('Otherwise Goto Node')"
            class="is-required"
          >
            <goto-node-condition
              v-model="otherwiseCondition"
              condition-keyword="Otherwise"
              :goto-options="generateGotoOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import MatchDaytime from "../components/MatchDaytime";
import GotoNodeCondition from "../components/GotoNodeCondition";
import TimezoneDropdown from "../../../../../../components/TimezoneDropdown";
import _ from "lodash";
import {
  filterRowsIfEveryKeyValueIsAbsent,
  filterRowsIfSomeKeyValueIsAbsent,
  getSubKeyObject,
  findKeywordCondition
} from "@/utils/collection";
import { mapGetters, mapState } from "vuex";
import { NODE_TYPES } from "@/constants/nodes";

export default {
  mixins: [BaseNode],
  components: {
    MatchDaytime,
    GotoNodeCondition,
    TimezoneDropdown
  },
  data() {
    const validateKeywordConditions = (rule, value, callback) => {
      let keywordConditions = filterRowsIfEveryKeyValueIsAbsent(
        _.reject(value, condition => condition.keyword === "Otherwise"),
        "keyword,node_name,day_of_week,from_time,to_time"
      );
      if (_.isEmpty(keywordConditions)) {
        callback();
      } else if (
        keywordConditions.length !==
        filterRowsIfSomeKeyValueIsAbsent(
          keywordConditions,
          "keyword,node_name,day_of_week,from_time,to_time"
        ).length
      ) {
        callback(__("Day time configuration incomplete"));
      }
      callback();
    };
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.daytime_node.data.keyword_conditions.data,
        { keyword: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        callback();
      }
    };
    const validateTimezone = (rule, value, callback) => {
      if (this.keywordConditions.length === 0) {
        callback();
        // callback("Otherwise node cannot be empty");
      } else {
        if (!this.timezoneSelection) {
          callback(__("Timezone cannot be empty"));
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        "daytime_node.data.keyword_conditions.data": {
          validator: validateKeywordConditions,
          trigger: "change"
        },
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        },
        selected_timezone: {
          validator: validateTimezone,
          trigger: "change"
        }
      },
      timezoneSelection: ""
    };
  },
  computed: {
    ...mapState("timezones", {
      timezoneList: state => state.timezones
    }),
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    ...mapGetters("timezones", {
      timezones: "timezones"
    }),
    timezonesList() {
      return this.timezoneList;
    },
    allKeywordConditions() {
      // console.log(this.nodeToBind.daytime_node.data.keyword_conditions, "daytime");
      return _.isEmpty(this.nodeToBind.daytime_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.daytime_node.data.keyword_conditions.data,

              keywordCondition => {
                if (this.isNodeSubmit === false) {
                  keywordCondition["error"] = "";
                  if (keywordCondition.node_id === -1) {
                    keywordCondition["error"] = !_.map(
                      this.getValidNodes,
                      "node_name"
                    ).includes(keywordCondition.node_name)
                      ? ""
                      : "Node '" +
                        `${keywordCondition.node_name}` +
                        "' cannot be used here";
                  }
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    " '" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = "";
                const subKeys = [
                  "node_id",
                  "node_name",
                  "keyword",
                  "day_of_week",
                  "from_time",
                  "to_time"
                ];
                keywordCondition["keyword_error"] = _.some(
                  this.nodeToBind.daytime_node.data.keyword_conditions.data,
                  condition =>
                    !_.isEqual(
                      getSubKeyObject(keywordCondition, subKeys),
                      getSubKeyObject(condition, subKeys)
                    ) &&
                    keywordCondition.keyword.toString().trim() ===
                      condition.keyword.toString().trim()
                )
                  ? "Keyword conflict"
                  : "";

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    keywordConditions: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition => condition.keyword !== "Otherwise"
            );
      },
      set: function(val) {
        const keywordMatchesExceptOtherwiseCondition = this.keywordConditions;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(keywordMatchesExceptOtherwiseCondition, val) ||
          _.isEmpty(keywordMatchesExceptOtherwiseCondition)
        ) {
          const otherwiseCondition = this.otherwiseCondition;
          const timezone = this.timezoneSelection;
          _.map(val, condition => {
            condition.timezone = timezone;
          });
          this.$set(
            this.nodeToBind.daytime_node.data.keyword_conditions,
            "data",
            val
          );

          if (!_.isEmpty(otherwiseCondition)) {
            this.nodeToBind.daytime_node.data.keyword_conditions.data.push(
              otherwiseCondition
            );
          }
        }
      }
    },
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "Otherwise");
      },

      set: function(otherwiseCondition) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === otherwiseCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.daytime_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: keyword_conditions } = this.allKeywordConditions;

      return (
        _.some(keyword_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(keyword_conditions, condition => {
          return condition.keyword_error && condition.keyword_error.length;
        })
      );
    },
    showTimeZone() {
      if (this.keywordConditions.length > 0) {
        const show = !_.every(this.keywordConditions, condition => {
          return _.every(
            ["keyword", "day_of_week", "from_time", "to_time", "node_name"],
            key => _.isEmpty(condition[key])
          );
        });
        if (!show) {
          this.handleTimezoneChange("");
        }
        return show;
      }
      return false;
    }
  },
  methods: {
    async handleTimezoneChange(timezone) {
      this.timezoneSelection = timezone;
      const currentKeywordConditions = this.keywordConditions;
      _.map(currentKeywordConditions, condition => {
        condition.timezone = timezone;
      });
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      // cleanup keyword_matches
      const keywordConditions = filterRowsIfEveryKeyValueIsAbsent(
        _.map(
          _.filter(
            this.allKeywordConditions.data,
            condition => condition.keyword !== "Otherwise"
          ),
          keywordCondition => ({
            ...keywordCondition,
            keyword: keywordCondition.keyword.toString().trim(),
            node_name: keywordCondition.node_name.toString().trim(),
            // timezone: this.timezoneSelection,
            day_of_week: _.isEmpty(keywordCondition.day_of_week)
              ? ""
              : keywordCondition.day_of_week.toString().trim(),
            from_time: _.isEmpty(keywordCondition.from_time)
              ? ""
              : keywordCondition.from_time.toString().trim(),
            to_time: _.isEmpty(keywordCondition.to_time)
              ? ""
              : keywordCondition.to_time.toString().trim(),
            msg: _.isEmpty(keywordCondition.msg)
              ? ""
              : keywordCondition.msg.toString().trim()
          })
        ),
        "keyword,node_name,day_of_week,from_time,to_time"
      );

      // cleanup keyword_matches
      const otherwiseCondition = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(
            _.filter(
              this.allKeywordConditions.data,
              condition => condition.keyword === "Otherwise"
            ),
            keywordCondition => ({
              ...keywordCondition,
              keyword: keywordCondition.keyword.toString().trim(),
              node_name: keywordCondition.node_name.toString().trim()
            })
          ),
          "keyword,node_name"
        ),
        condition => {
          const { keyword, node_id, node_name, msg } = condition;
          return {
            keyword,
            node_id,
            node_name,
            msg
          };
        }
      );

      const daytimeConditions = _.concat(keywordConditions, otherwiseCondition);

      this.$set(
        nodeToCleanUp.daytime_node.data.keyword_conditions,
        "data",
        daytimeConditions
      );

      return nodeToCleanUp;
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.daytime_node)) {
      this.$set(this.nodeToBind, "daytime_node", {});
      this.$set(this.nodeToBind.daytime_node, "data", {});
      if (_.isEmpty(this.nodeToBind.daytime_node.data.keyword_conditions)) {
        this.$set(this.nodeToBind.daytime_node.data, "keyword_conditions", {});
        // this.$set(
        //   this.nodeToBind.daytime_node.data.keyword_conditions,
        //   "data",
        //   []
        // );
      }

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.DAYTIME.NODE_TYPE);
    } else {
      // console.log(this.nodeToBind);
      this.timezoneSelection = this.nodeToBind.daytime_node.data.keyword_conditions.data[0][
        "timezone"
      ];
      //this.keywordConditions = this.nodeToBind.daytime_node.data.keyword_conditions.data;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
