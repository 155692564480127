<template>
  <div class="matchDaytimeTable">
    <el-table
      class="variable-rules"
      fit
      :data="keywordMatches"
      style="width: 100%;"
    >
      <el-table-column prop="keyword" :label="__('Alias')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col :class="classes(scope.row, 'keyword')">
              <el-form-item :error="scope.row.keyword_error">
                <el-input v-model="scope.row.keyword"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="day_of_week" :label="__('Day of Week')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col :class="classes(scope.row, 'day_of_week')">
              <el-form-item>
                <el-select
                  v-model="scope.row.day_of_week"
                  :placeholder="__('Select day')"
                  default-first-option
                  style="width:130px"
                >
                  <el-option
                    v-for="day in days_of_week"
                    :key="day.value"
                    :label="day.label"
                    :value="day.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="from_time" :label="__('Start Time')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col :class="classes(scope.row, 'from_time')">
              <el-form-item>
                <el-time-select
                  :placeholder="__('Start time')"
                  v-model="scope.row.from_time"
                  style="width: 130px; padding-left: 5px"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:30'
                  }"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="to_time" :label="__('End Time')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col :class="classes(scope.row, 'to_time')">
              <el-form-item>
                <el-time-select
                  :placeholder="__('End time')"
                  v-model="scope.row.to_time"
                  style="width: 130px; padding-left: 5px"
                  :picker-options="{
                    start: '00:30',
                    step: '00:15',
                    end: '24:00',
                    minTime: scope.row.from_time
                  }"
                >
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="node_name" :label="__('Goto Node')">
        <template slot-scope="scope">
          <el-row
            :gutter="20"
            class="goto-node-configure"
            type="flex"
            justify="start"
          >
            <el-col :class="classes(scope.row, 'node_name')">
              <el-form-item>
                <create-or-select
                  :items="extractGotoNodeOptions"
                  :current_select="currentSelection(scope.row)"
                  :fail-to-create-message="scope.row.error"
                  :new-item-message="__('new node')"
                  :placeholder="__('Node Name')"
                  @change="handleChange($event)(scope.row)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column class-name="row-message" width="100px" prop="msg" />
      <el-table-column class-name="cell-item-pointer" width="40px">
        <template slot-scope="scope">
          <span @click="removeKeywordMatch(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    gotoOptions: {
      required: true,
      type: Array
    }
  },
  data() {
    const keyword_match = {
      keyword: "",
      node_name: "",
      node_id: -1,
      day_of_week: "",
      from_time: "",
      to_time: ""
    };
    return {
      keyword_match,
      $keyword_matches: [{ ...keyword_match }],
      $gotoOptions: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      days_of_week: [
        {
          value: "Monday",
          label: __("Monday")
        },
        {
          value: "Tuesday",
          label: __("Tuesday")
        },
        {
          value: "Wednesday",
          label: __("Wednesday")
        },
        {
          value: "Thursday",
          label: __("Thursday")
        },
        {
          value: "Friday",
          label: __("Friday")
        },
        {
          value: "Saturday",
          label: __("Saturday")
        },
        {
          value: "Sunday",
          label: __("Sunday")
        },
        {
          value: "Weekday",
          label: __("Weekday")
        },
        {
          value: "Weekend",
          label: __("Weekend")
        },
        {
          value: "Everyday",
          label: __("Everyday")
        }
      ]
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    keywordMatches() {
      return this.$data.$keyword_matches;
    },

    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    },

    classes() {
      return (row, key) => {
        if (
          !row.keyword &&
          !row.day_of_week &&
          !row.from_time &&
          !row.to_time &&
          !row.node_name
        ) {
          return "empty-row empty-val";
        }
        // if (key === "description" && !row.import_configurable) {
        //   return "no-red-highlight";
        // }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    }
  },
  methods: {
    addNewKeywordMatch() {
      this.$data.$keyword_matches.push({ ...this.keyword_match });
    },

    removeKeywordMatch(index) {
      this.$data.$keyword_matches.splice(index, 1);
    },

    initializeKeyWordMatches(keywordMatches) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(keywordMatches) && keywordMatches.length) {
          this.$data.$keyword_matches = _.cloneDeep(keywordMatches);
        }
        if (
          !_.some(
            this.$data.$keyword_matches,
            keywordMatch => !keywordMatch.keyword.length
          )
        ) {
          this.addNewKeywordMatch();
          this.$emit("input", _.cloneDeep(this.$data.$keyword_matches));
        }
      }
    },

    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeKeyWordMatches"
    },
    keywordMatches: {
      handler: function(keywordMatches) {
        this.$emit("input", _.cloneDeep(keywordMatches));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
/*.goto-node-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-node-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/

.matchDaytimeTable ::v-deep .el-table--medium td {
  padding: 0;
}

.matchDaytimeTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.matchDaytimeTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.matchDaytimeTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.matchDaytimeTable ::v-deep .el-table::before {
  background-color: white !important;
}

.matchDaytimeTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.matchDaytimeTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}
</style>
